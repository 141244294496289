import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bench Press 6-6-6-6\\@80% 1RM`}</p>
    <p>{`Strict CTB Pullups 6-6-6-6`}</p>
    <p>{`then,`}</p>
    <p>{`60-Double Unders`}</p>
    <p>{`50-Pushups`}</p>
    <p>{`40-Pullups`}</p>
    <p>{`30-GHD Situps`}</p>
    <p>{`50-Double Unders`}</p>
    <p>{`40-Pushups`}</p>
    <p>{`30-Pullups`}</p>
    <p>{`20-GHD Situps`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Thursday the Ville will be closed from 11:30-4:30 for a video
shoot.  Only the 12:00 class will be cancelled.  We do need some members
to be working out in the background so if you’d like to help out please
contact Daniel.  Thanks!`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      